$rt-color-info: $info;
$rt-color-success: $success;
$rt-color-warning: $warning;
$rt-color-error: $danger;
$rt-text-color-default: $body-color;

@import '~react-toastify/scss/variables';
@import '~react-toastify/scss/toastContainer';
@import '~react-toastify/scss/closeButton';
@import '~react-toastify/scss/progressBar';

// // entrance and exit animations
@import '~react-toastify/scss/animations/bounce.scss';
.#{$rt-namespace}__toast-container {
  margin-top: 140px;

}
.#{$rt-namespace}__toast {
  display: flex;
  justify-content: space-between;
  padding-right: 30px;
  @extend .alert;
  &--info {
    @extend .alert-info;
  }
  &--success {
    @extend .alert-success;
  }
  &--warning {
    @extend .alert-warning;
  }
  &--error {
    @extend .alert-danger;
  }
}

.#{$rt-namespace}__close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  color: inherit;
  outline: none !important;
}
