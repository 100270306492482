// Menu Section Page Style.
.orders-page {
  //  padding:0 16px;
}
.section-title {
  h4 {
    color: #222933;
    // font-size: 1.5rem;
    // line-height: 1;
    display:block;
    font-size: 22px;
    line-height: 30px;
    font-weight: 600 ; //$font-weight-bolder
    padding: 0;
    margin: 0;
    // padding-bottom: 0.5rem;
    // border-bottom: 1px solid $border-color;
    // margin-bottom: 1rem;
  }
}

@media only screen and (max-width: 1091px) {
  .section-title {
    h4 {
      font-size: 1rem;
    }
  }
}
