$green-light: #00ff98;
// $primary: #ff5d5d;
$secondary: #ffffff;

// $body-color: #bfbfbf;
$body-bg: #f3f5f8;
// $bg-light: #f3f5f8;
$body-color: #3b4452;
// $color-dark: black;
$font-weight-small: 400;
$font-weight-bolder: 500;

// $font-family-base: 'Roboto', sans-serif;
// $secondary: red;

$grid-gutter-width: 16px;
$modal-md: 991px;

// $btn-border-radius: 40px;
$btn-focus-box-shadow: none;
$input-btn-font-size-lg: 0.875rem;
$input-btn-padding-y-lg: 0.825rem;
$input-btn-padding-x-lg: 1rem;

$modal-backdrop-bg: white;
$modal-backdrop-opacity: 0.8;
$form-check-input-margin-y: 0.1rem;
$form-check-input-gutter: 1.5rem;

$navbar-light-color: $body-color;
$navbar-light-active-color: rgba($body-color, 0.5);
$navbar-padding-y: 0;
$navbar-brand-padding-y: 0;
$nav-link-padding-y: 0.825rem;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 1092px,
  xl: 1920px,
);

$subMenu-bg: #ff6a00;
$subMenu-height: 70px;
$header-height: 80px;
$header-height-sm: 64px;

// $datepicker__border-color:
$datepicker__selected-color: $body-color;
$datepicker__text-color: $body-color;
$datepicker__header-color: $body-color;
