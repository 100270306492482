// Info Modal page Style.

.modal-info {
  background-color: #f4f4f4;
  .modal-header {
    border-bottom: 1px solid #e7e7e7;
    .info-title {
      font-size: 18px;
      color: #0f1213;
    }
  }
  .more-title h5 {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    .btn-secondary {
      border-color: transparent;
    }
    .btn-secondary:not(:disabled):not(.disabled):active,
    .btn-secondary:not(:disabled):not(.disabled).active,
    .show > .btn-secondary.dropdown-toggle {
      background-color: transparent;
      border-color: transparent;
    }
    .btn-secondary:focus,
    .btn-secondary.focus {
      box-shadow: none;
    }
  }
  .row {
    margin-left: -16px;
    margin-right: -16px;
  }
  .contact-info {
    padding-left: 0;
  }
  .contact-title {
    font-size: 16px;
    font-weight: 800;
  }
  .list-border {
    border-bottom: 1px solid #e7e7e7;
  }
  .day-list {
    width: 40px;
    font-weight: bold;
    font-size: 12px;
  }
  .hour-list {
    font-size: 12px;
  }
}

@media only screen and (min-width: 1092px) {
  .modal-info {
    .modal-body {
      padding: 1.5rem;
    }
    .day-list {
      font-size: 16px;
    }
    .hour-list {
      font-size: 16px;
    }
  }
}
