// Header Page Style.

.app-header {
  background-color: #ffffff;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;

  .header-container {
    padding-left: 0;
  }

  .navbar {
    align-items: stretch;
  }
  .navbar-nav {
    align-self: stretch;
  }
  .nav-item {
    margin: 0 15px;
    &:last-child {
      margin: 0;
    }
  }
  .nav-link {
    display: flex;
    align-items: center;
    height: 100%;
    margin-bottom: -1px;
    .info-link {
      color: #8a8c8c;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #8a8c8c;
    }
    &.active {
      box-shadow: inset 0 -2px 0 $primary;
    }
  }
  .backdrop {
    display: none;
  }
  .head-border {
    height: 4px;
    background-color: $subMenu-bg;
  }
  .header-cart-nav {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .iconRestaurant {
    justify-content: center;
    margin-left: 8px;

    .res-address {
      font-size: 14px;
      color: #0f1213;
    }
  }
  .headerIcons {
    box-shadow: none !important;
    text-decoration: none;
    padding-right: 0;

    img {
      width: 32px;
      height: 32px;
    }
    .cart-count {
      position: relative;
      bottom: 10px;
      font-weight: bold;
      font-size: 14px;
    }
  }
}

@media (min-width: 1092px) {
  .iconRestaurant {
    width: 552px;
    text-overflow: ellipsis;
    > span {
      width: 552px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  .app-header {
    .headerIcons {
      padding-left: 1rem;
    }
  }
}

// "TAQUERIA'S GUADALAJARA 111".split('\n').length === 3

@include media-breakpoint-down('md') {
  .iconRestaurant {
    justify-content: center;
    width: 197px;
    text-overflow: ellipsis;
    margin-left: 8px;
    > span {
      width: 197px !important;
      white-space: pre-line;
      display: -webkit-box;
      max-width: 197px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    margin-left: 8px;
    span {
      text-overflow: ellipsis;
      width: 50px;
      // white-space: pre-wrap;
      white-space: pre-line;
    }
    .title {
      font-size: 16px !important;
    }
  }
  .modal-dialog {
    width: 100%;
    padding: 0;
  }
  .app-header .navbar {
    height: 64px;
  }
  .headerIcons img {
    width: 24px;
    margin-top: 0;
  }
  .app-header .container {
    height: 100%;
  }
  .navbar-brand {
    height: 100%;
    display: flex;
    margin-right: 0;
  }
  .info-link {
    margin-top: 0 !important;
  }
}
