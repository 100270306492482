// Button Style.

.btn-clear {
  background: none;
  box-shadow: none;
  appearance: none;
  border: 0;
}

.btn-loading {
  color: transparent !important;
  background-image: url('../../assets/icons/loading.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.btn-file-upload {
  input {
    display: none;
  }
}

.btn-default {
  height: 3.125rem;
  line-height: 2.125rem;
  background: #ffffff;
  text-transform: uppercase;
  padding: 0.5rem 1rem;
  border-radius: 0;
  box-shadow: 0 6px 8px 0 rgba(203, 203, 203, 0.3);
  svg {
    vertical-align: middle;
  }
  &:focus,
  &:hover {
    box-shadow: 0 6px 8px 0 rgba(203, 203, 203, 0.7);
  }
}

.btn-primary {
  color: #fff;
  background-color: #ff6a00;
  border-color: #ff6a00;
  &:hover,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background-color: #ff6a00;
    border-color: #ff6a00;
  }
  &:focus {
    background-color: #ff6a00;
    border-color: #ff6a00;
    box-shadow: 0 0 0 0.2rem #ffc107;
  }
}

.btn.disabled,
.btn:disabled {
  opacity: 1;
  background-color: #8a8c8c !important;
}

.btn-link,
.btn-link:hover {
  color: #070706;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: none !important;
}
