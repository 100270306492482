// Date Component Style.

.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today {
  border-radius: 0.3rem;
  border: 1px solid black;
  font-weight: normal;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range {
  outline-color: aliceblue;
}
.calendarLabelError {
  color: red;
  font-size: 13px;
  width: 100%;
  margin-top: 0.25rem;
  color: #dc3545;
}

.calendarInvalid {
  border: 1px solid #dc3545 !important;
}

.calendarInvalid:focus {
  box-shadow: 0 0 0 0.2rem #ff808052;
}

.calendarValid:focus {
  box-shadow: 0 0 0 0.2rem #80bdff52;
}

.calendarValid {
  border: none !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: #fff !important;
  color: #3b4452 !important;
}

.react-datepicker__day--selected, .react-datepicker__time-list-item--selected {
  background-color: #ff6a00 !important;
  color: #fff !important;
  border: none;
}
