// Product Item Component Style.

.product-item {
  border:1px solid grey;
  padding: 0  0  0 16px;
  border-radius:5px;
  overflow: hidden;
  // width: 100%;
  // display: block;
  // text-align: inherit;
  // color: inherit;
  // border: 0;
  // cursor: pointer;
  margin: 0;
  outline: 0;
  // padding: 0;
  position: relative;
  user-select: none;
  // display: flex;
  position: relative;
  background: #ffffff;
  margin: 0 0 20px;
  cursor: pointer;
  .item-image {
    // width: 128px;
    // height: 128px;
    width: 100px;
    height: 100px;
    position: relative;
    // img {
    //   position: absolute;
    //   width: 100%;
    //   height: 100%;
    //   object-fit: cover;
    //   object-position: center;
    //   border-radius: 25px;
    // }
    border-radius: 5px;
    overflow:hidden;
  }
  .item-body {
    flex: 1;
    display: flex;
    flex-direction: column;
    // min-width: 0;
    // padding: 20px;
    // position: relative;
    // height: 128px;

    -webkit-box-pack: center;
    justify-content: center;
    max-width: 100%;
    min-height: 132px;
    min-width: 0px;
    padding-right: 0px;
  }
  .item-cart {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  // .item-name {
  //   color: #222933;
  //   font-size: 16px;
  //   font-weight: bold;
  //   white-space: nowrap;
  //   overflow: hidden;
  //   text-overflow: ellipsis;
  //   margin-bottom: 0;
  //   font-family: 'MONTSERRAT';
  // }
  // .item-description-text {
  //   display: -webkit-box;
  //   -webkit-line-clamp: 2;
  //   -webkit-box-orient: vertical;
  //   overflow: hidden;
  //   margin-bottom: 2px;
  // }
  // .item-description {
  //   color: #657786;
  //   flex: 1;
  //   font-size: 14px;
  //   white-space: pre-line;
  // }
  // .item-price {
  //   font-size: 16px;
  //   font-weight: 800;
  //   color: #007d4a;
  // }
  margin-bottom: 4px;
  .cart-btn {
    position: absolute;
    bottom: 0;
    right: 0;
    font-weight: $font-weight-small;
    font-size: 14px;
    line-height: 20px;
    color: #3b4452;
    align-items: center;
    background: #ffffff;
    padding: 1rem 1.5rem;
    font-size: 14px;
    svg {
      margin-right: 5px;
      vertical-align: top;
      height: 20px;
    }
  }
}

@media only screen and (max-width: 1091px) {
  .product-item {
    margin: 0 0 16px;
    .item-image {
      width: 128px;
      height: 128px;
    }
    .item-name {
      font-size: 16px;
    }
    .item-body {
      padding: 10px;
      height: 128px;
    }
    .item-description-text {
      margin-bottom: 10px;
    }
    .cart-foot {
      position: static;
      bottom: 0;
    }
  }
}
