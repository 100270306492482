// Modifiers Component Style

.modifier {
  margin-bottom: 0.5rem;
}
.modifier-option {
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  &.error {
    border-color: $danger;
  }
  .item-name {
    margin-right: 1rem;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
.modifier-desc {
  flex-direction: column;
}
