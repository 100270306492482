// SideBar Component Style.

.sidebar-col {
  border-right: 1px solid $border-color;
  padding-right: 0;
}
.sidebar {
  position: sticky;
  top: 70px;
  .nav-item {
    padding-bottom: 20px;
    &.active {
      box-shadow: inset -4px 0 0 #3b4452;
    }
    .nav-link {
      display: flex;
      align-items: center;
      min-height: 32px;
      padding: 0 1rem 0 0;
      line-height: 1.5;
      color: #222933;
      font-weight: $font-weight-small;
      font-size: 16px;
    }
  }
}
.menu-sidebar {
  position: relative;
  top: 30px;
  right: 20px;
}
.border-side {
  border-bottom: 1px solid #ff6a00;
}
.side-title {
  font-size: 16px;
  color: #0f1213;
}

.makeStyles-list-1 {
  filter: grayscale(20%);
  height:100%
}


.MuiButtonBase-root {
  .active {
    color: black !important;
    font-weight: 600;
  }
}

.MuiCard-root {
  box-shadow: 0px 0px 10px #CECECE3D;
}

#sidebar-left {
  .MuiList-root {
    > div > a {
      width: 100%;
    }
  }
}
