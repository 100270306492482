// Contact Page Style.

.contacts-page {
  .brand-logo {
    width: 100px;
    height: 100px;
    margin-bottom: 1rem;
  }
}
.contact-info {
  position: relative;
  padding-left: 2rem;
  line-height: 1.5rem;
  svg {
    position: absolute;
    width: 1.25rem;
    height: 1.25rem;
    top: 0;
    left: 0;
  }
}
.iframe-wrapper {
  position: relative;
  padding-bottom: 65%;
  margin: 0.5rem 0;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
