// Orders Page Style.

.menu-section {
  &:first-child {
    padding-top: 0;
  }
  margin-bottom: 1rem;
}
.order-success-page {
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.frontpage-job:hover,
.frontpage-job:active {
  text-decoration: none;
}
.not-found-product {
  text-align: center;
  font-size: 34px;
  margin-top: 212px;
  color: #8a8c8c;
  width: 436px;
  font-weight: 300;
  letter-spacing: 0px;
  opacity: 1;
}
.goCheckoutButton {
  bottom: 0;
  border-radius: 0;
  font-size: 20px;
  height: 100%;
  width: 100%;
  font-weight: 100;
  height: 60px;
  font-size: 18px;
  position: fixed;
  bottom: 0;
  left: 0;
  border: none;
  background: #ff6a00;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    padding-left: 10px;
    font-size: 14px;
    position: absolute;
    right: 16px;
    line-height: 16px;
  }
}

.categoryDisabled {
  cursor: default;
  color:#707070 !important
}

.productDisabled {
  cursor:default;
  background-color: #80808021;
  .item-name {
    color:#9ba6ae
  }
  .item-price {
    color:#9ba6ae
  }
}
