// Cart Pages Style.

.cart-page {
  width:100%;
  max-width: 1092px;
  margin: 0 auto;
  padding: 40px 0 0 0;
  .btn-link {
    display: flex;
    align-items: center;

    svg {
      margin-right: 10px;
    }
  }
  .cart-item {
    .item-action .input-group input.form-control {
      border: none;
      font-weight: bold;
    }
    .btn-secondary:not(:disabled):not(.disabled):active,
    .btn-secondary:not(:disabled):not(.disabled).active,
    .show > .btn-secondary.dropdown-toggle {
      background-color: transparent;
      border-color: transparent;
    }
    .btn-secondary:hover,
    .btn-secondary:focus,
    .btn-secondary.focus {
      outline: none;
      border-color: transparent;
      background-color: transparent;
      box-shadow: none;
    }
    svg .a {
      fill: transparent;
    }
    .plus-icon .a {
      fill: #0f1213;
    }
    .content-muted {
      display: -webkit-box;
      // -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  .cart-body-content {
    // background-color: #ededed;
    .cashback-text {
      color: #8a8c8c;
    }
  }
  .form-section {
    .tab-content > .active {
      padding: 1rem 0;
      font-size: 12px;
      color: #0f1214;
    }
    .nav-tabs {
      background-color: #ffffff;
      padding: 0 16px;
      height: 52px;
      .nav-item {
        display: flex;
        align-items: center;
        .nav-link {
          padding: 0;
        }
      }
    }
    .cartNameRow {
      margin-bottom: 120px;
    }
    legend {
      font-size: 16px;
      color: #8a8c8c;
      font-weight: bold;
    }
    fieldset {
      .form-control {
        background-color: transparent;
        .custom-control-input:checked ~ .custom-control-label::before {
          background-color: #ff6a00;
          border-color: #ff6a00;
        }
        .custom-control-label {
          color: #272833;
          font-size: 18px;
          margin-bottom: 10px;
        }
      }
      .group-date {
        .form-control {
          background-color: white;
        }
      }
    }
    .deliveryGroup {
      display: flex;
    }
    .group-date {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      .text-muted {
        font-size: 12px;
        color: #0f1214;
      }
    }
  }
  .footer-section {
    background-color: #ffffff;
    .checkout-form-title {
      border-bottom: 1px solid #ededed;
    }
    .text-color {
      color: #ff6a00;
    }
  }
}

.cart-page-content {
  margin: 0 auto;
  max-width: 1092px;
  // border: 1px solid $border-color;
  background-color: #f4f4f4;
  border-radius: $border-radius;
}

.cart-top-container {
  display: flex;
  margin-bottom: 20px;
}

.cart-header {
  padding: 0;
  border: 0;
  position: relative;

  .btn {
    position: absolute;
    left: 0;
    bottom: 100%;
    color: inherit;
    text-decoration: none;

    svg {
      vertical-align: top;
      width: 18px;
      height: 22px;
      margin-right: 5px;
    }
  }

  .item-media {
    width: 400px;
    height: 100%;
    background: #96969626;

    img {
      width: 100%;
      object-fit: cover;
      background-color: $gray-100;
      border-radius: $border-radius;
    }
  }

  .cart-clipboard-icon {
    left: auto;
    right: 0;
  }

  .cart-title-icon {
    position: absolute;
    z-index: 22;
    bottom: 100%;
    left: 50%;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #ff6a00;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    transform: translateY(50%) translateX(-50%);
    align-items: center;
    justify-content: center;
    display: flex;

    svg {
      margin-right: 3px;
      color: #fff;
    }
  }
}

.cart-item {
  display: flex;
  align-items: center;
  padding: 16px;
  margin-top: 20px;
  background-color: #ffffff;
  border-radius: 5px;
  border:1px solid #e4e4e4;
  .item-title {
    font-size: 16px;
    font-weight: bold;
    color: #0f1214;
  }
  .text-muted {
    font-size: 14px;
  }

  .actions-price {
    color: #0f1214;
    font-size: 16px;
    font-weight: bold;
  }
  .container {
    display: none;
  }

  .item-media img {
    width: 80px;
    object-fit: cover;
    height: 80px;
    background-color: #f8f9fa;
    border-radius: 0.25rem;
  }

  // &:first-child {
  //   border-top: 0;
  // }

  .item-content {
    flex: 1;

    b {
      color: #545454;
      font-size: 14px;
    }

    span {
      display: block;
      font-weight: 600;
      font-size: 14px;
      color: #007d4a;
    }
  }
}

.item-action {
  display: flex;
  justify-content: flex-end;

  button {
    width: 38px;
    text-align: center;
  }

  .input-group {
    width: auto;

    input.form-control {
      width: 40px;
      height: 100%;
    }
  }

  .form-control {
    width: 38px;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
    background-color: #fff !important;
    border-color: $border-color;
  }
}

.cart-body {
  width: 100%;
  padding: 1rem;
}

.cart-footer {
  border-top: 1px solid $border-color;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .alert {
    margin: 0;
  }
  .btn-place {
    width: 100%;
    background-color: #ff6a00;
    color: white;
    font-size: 18px;
    border-radius: 0;
    border-color: transparent;
  }
  .btn-success:focus,
  .btn-success.focus {
    box-shadow: none;
  }
  .btn-success:not(:disabled):not(.disabled):active,
  .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    background-color: #ff6a00;
    border-color: transparent;
  }
  .btn-success:not(:disabled):not(.disabled):active:focus,
  .btn-success:not(:disabled):not(.disabled).active:focus,
  .show > .btn-success.dropdown-toggle:focus {
    box-shadow: none;
  }
}

.cart-body-content {
  padding: 0;
  margin: 0 1.5rem;
}

.checkout-form {
  padding-top: 1.5rem;

  .checkout-form-title {
    border-bottom: 1px solid #ff6a00;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    font-size: 18px;
  }

  .form-section {
    margin-bottom: 3rem;
    border-radius: 6px;
    //outline: 0 auto;
    border-color: transparent;
    &:last-child {
      padding-bottom: 0;
    }
  }

  .nav-tabs {
    .nav-item {
      margin-right: 1.5rem;
    }

    .nav-link {
      border: 0;
      padding: 0.5rem 0;
      color: #3b4452;
      &.active {
        margin: 0;
        border: 0;
        background: none;
        box-shadow: inset 0 -3px 0 #ff6a00;
        font-weight: bold;
      }
    }
  }

  .tab-pane {
    padding: 1.5rem 0;
  }

  .order-summary {
    td {
      padding: 5px 0;

      &:last-child {
        padding-left: 1.5rem;
        font-weight: 500;
      }
    }

    .total {
      font-size: 1.125rem;
      border-top: 1px solid $border-color;
    }
  }
}

.cart--modal {
  .item-media {
    width: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .cashback-text {
    color: #8a8c8c;
  }

  .card--info-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 16px;
  }

  .modal-header {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 999;
    border: none;
    padding: 0.75rem;

    .modal-title {
      width: 100%;
      text-align: right;
    }

    button {
      border: none;
      padding: 0;
      opacity: 0.5;
      width: 30px;
      height: 30px;
      svg {
        color: black;
      }
    }
  }

  .modal-body {
    display: flex;
    padding: 0;

    .row {
      width: 100%;
    }
  }

  .cart-body-content {
    margin: 0;
  }

  .card--content-wrapper {
    flex-grow: 1;
  }

  textarea {
    height: 100px;
    border-radius: 10px;
  }

  .card--product-info {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}

.modal-backdrop {
  background-color: #ffffffbd;
}

.modal-backdrop.show {
  @media (max-width: 767px) {
    opacity: 1;
  }
}

.cart--modal-single {
  width: 520px;
  height: 600px;
  .modal-header {
    width: 100%;
  }

  .card--info-container {
    width: 100%;
  }

  .row {
    @media only screen and (min-width: 769px) {
      margin: 0;
    }
  }
}

.card--multiply-btn {
  .btn.btn-secondary {
    width: 40px;
    height: 40px;
    font-size: 20px;
    line-height: 1.3;
    border-radius: 50%;
    background-color: #f9f9f9;
  }
  .btn-icon {
    padding: 0;
  }

  input.form-control {
    width: 40px;
    flex: initial;
    background: transparent;
    border: none;
    height: 44px;
    width: 50px;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
  }

  .input-group {
    width: auto;
    justify-content: center;
  }

  .input-group-prepend,
  -group-append {
    align-items: center;
  }
}

.apply-btn {
  background-color: #ffffff;
  border-radius: 5px;
}

.btn-link:focus,
.btn-link.focus {
  text-decoration: auto;
}

.deliveryRadios .form-control {
  display: flex;
}

.deliveryRadios {
  align-items: flex-end;
  margin-right: 10px;
  padding-top: 13px;
}

.delivery-tip-container {
  display: flex;
  align-items: center;
}

@include media-breakpoint-down('md') {
  .cart-item {
    flex-wrap: wrap;
    justify-content: flex-end;

    .item-content {
      min-width: 70%;
    }

    .actions {
      margin-top: 1rem;
    }

    > .btn {
      margin-top: 1rem;
    }
  }
}

@media only screen and (min-width: 1092px) {
  .cart--modal {
    flex-direction: row;
    height: 600px;
    padding: 0;
    .form-section {
      .nav-tabs {
        width: max-content;
      }

      .nav-tabs {
        width: fit-content;
      }
    }
  }
  .cart-page {
    .form-section {
      fieldset {
        .form-control {
          .custom-control-label {
            font-size: 16px !important;
          }
        }
      }

      .nav-tabs {
        width: max-content;
        // padding: 0 5px;
        height: 80px;
        .nav-item {
          margin-left: 27px;
          margin-right: 27px;
          font-size: 20px;
        }
      }
      legend {
        font-size: 20px;
      }
      .deliveryGroup .custom-control-label {
        font-size: 16px;
      }
    }
  }
  .cart--modal-single {
    width: 520px !important;
  }
  .cart--modal-single {
    width: 520px !important;
    height: 600px !important;
  }
  .cart-page {
    padding: 40px 0 0 0;
    .checkout-form {
      .checkout-form-title {
        font-size: 20px;
      }
    }
  }
  .placeInputField .invalid-feedback {
    position: static;
    font-size: 13px;
  }
  .placeInputField ::placeholder {
    color:#ced0d2
  }
  .errorMessage {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 13px;
    color: #dc3545;
  }
}
