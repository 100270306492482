.marketplaceImg {
    width:100%;
    position: absolute;
    bottom: 20px;
}

.searchInput {
    position: relative; 
    margin-top: 175px; 
    z-index: 1; 
    width: 697px;
    height: min-content;
    border-radius: 8px;
    background:white;
    font-family:Roboto;
    /* display:flex; */
    /* align-items: center; */
    /* justify-content: center; */
}

/* .MuiTypography-root {
    font-family: Roboto
} */

.iconButton:hover {
    background: #ffa767;
}

.MuiPaper-root {
    box-shadow: 0px 0px 10px #CECECE3D !important;
}

.addressSearch legend {
    display:none
}

.addressSearch fieldset {
    top:0;
    display:none
}

.MuiInputBase-root {
    background: white;
    height:47px;
}

.restaurantTitle {
    font-size:20px;
    margin: 0;
    font-weight: bold !important;
    font-size: 1.5rem;
    line-height: 1.334;
    letter-spacing: 0em;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
}

.fullAddress {
    text-overflow: ellipsis;
    font-weight: normal;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
}


@media only screen and (max-width: 900px) {
  

    .marketplaceImg {
        width:100%;
        position: absolute;
        bottom: 20px;
    }
   
}
