// Product View Component Style.

.product-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1100;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.5);
  animation: fadeIn 0.4s ease-in-out forwards;
}

.product-view {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 520px;
  z-index: 1200;
  background-color: #fff;
  transform: translateX(100%);
  animation: slide 0.4s ease-in-out forwards;
  display: flex;
  flex-direction: column;
  .item-image {
    padding-bottom: 45%;
    position: relative;
    &:after {
      content: '';
      box-shadow: inset 0 0 0 1000px rgba(34, 41, 51, 0.2);
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(180deg, rgba(34, 41, 51, 0) 0%, #222933 100%);
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .item-header {
    position: absolute;
    z-index: 11;
    bottom: 0;
    left: 0;
    width: 100%;
    color: white;
    padding: 1rem;
    display: flex;
    align-items: flex-end;
  }
  .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.5rem;
    z-index: 22;
    color: white;
    opacity: 1;
    svg {
      width: 2rem;
      height: 2rem;
    }
  }
  .item-name {
    flex: 1;
    font-size: 1.625rem;
    font-weight: $font-weight-small;
    padding-right: 1rem;
  }
  .item-price {
    font-weight: $font-weight-small;
    font-size: 1.5rem;
    color: $green-light;
  }
  .item-description {
    font-size: 14px;
    color: $text-muted;
  }
  .item-body {
    padding: 20px;
    flex: 1;
    overflow: auto;
  }
  .item-footer {
    padding: 20px;
  }
}

.hidden {
  .product-backdrop {
    animation: fadeOut 0.4s ease-in-out forwards;
  }
  .product-view {
    animation: slideOut 0.4s ease-in-out forwards;
  }
}

@keyframes slide {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
