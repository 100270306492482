// Menu Navbar Pages Style.

.menu-navbar {
  width: 100%;
  border-radius: 30px;
  margin: 10px 0;
  background-color: #f4f4f4;
  z-index: 11;
  height: 60px;
  display: flex;
  align-items: center;
  padding: 0 24px;
}

.banner-header {
  position: sticky;
  top: 111px;
  left: 0;
  width: 100%;
  z-index: 997;
  background-color: #ffffff;
}

.category-dropdown-wrapper {
  display: flex;
  align-items: center;
  h5 {
    // font-size: 17px;
    // margin: 0 1rem 0 0;
    font-size:'16px';
    font-weight: $font-weight-bolder;
  }
  .category-dropdown {
    .dropdown-toggle {
      color: $body-color;
      border: 1px solid $body-color !important;
      background-color: transparent !important;
      box-shadow: none !important;
      padding-left: 1rem;
      padding-right: 1rem;
      &:after {
        margin-left: 0.5rem;
        vertical-align: 0.155em;
      }
    }
    .dropdown-menu {
      overflow: auto;
      max-height: 260px;
      padding: 0.5rem 0;
      margin-top: 0.5rem;
      border: 0;
      box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.2);
      border-radius: 4px;
    }
    .dropdown-item {
      border-bottom: 1px solid $border-color;
      font-size: 0.875rem;
      line-height: 1.5rem;
      padding: 0.5rem 1rem;
      min-width: 230px;
      cursor: pointer;
      &:last-child {
        border-bottom: 0;
      }
      &:active,
      &.active {
        outline: none;
        color: $dropdown-link-hover-color;
        background-color: $dropdown-link-hover-bg;
        &:hover {
          color: $dropdown-link-hover-color;
        }
      }
    }
  }
}

.navbar-search {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  width: 100%;
  .form-control {
    border-radius: 0;
    border: 0;
    flex: 1;
    width: 156px;
    margin-right: 1rem;
    box-shadow: none !important;
    background-color: transparent;
    height: auto;
    line-height: 1;
    padding: 6px 16px;
  }
  svg {
    color: $body-color;
  }
}

@media only screen and (max-width: 1091px) {
  .menu-navbar {
    .navbar-search {
      // padding: 0 1rem;
      z-index: 1;
      .form-control {
        width: 0;
        margin-right: 0;
        transition: all 0.4s ease-in-out;
        &:not([value='']),
        &:focus {
          margin-right: 1rem;
        }
      }
    }
  }
  .menu-navbar {
    height: 40px;
    .navbar-search {
      .form-control {
        &:focus {
          width: calc(720px - 7.5rem);
        }
      }
    }
  }
  .banner-header {
    position: sticky;
    top: 68px;
    left: 0;
    width: 100%;
    z-index: 997;
    // background-color: #f4f4f4;
    display: flex;
    align-items: center;
  }
}

@media only screen and (min-width: 1092px) {
  .menu-navbar {
    .navbar-search {
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
}
