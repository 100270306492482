// Auth Page Style.

.auth-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 80px $grid-gutter-width/2;
}
.auth-box {
  background-color: #fff;
  border-radius: 6px;
  max-width: 420px;
  padding: $grid-gutter-width;
  width: 100%;
}
